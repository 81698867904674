import new20210628 from './news-list/new_20210628_zh.json';
import new20210527 from './news-list/new_20210527_zh.json';
import new20210826 from './news-list/new_20210826_zh.json';
import new20210824 from './news-list/new_20210824_zh.json';
import new20210827 from './news-list/new_20210827_zh.json';
import new20210924 from './news-list/new_20210924_zh';
import new20211029 from './news-list/new_20211029_zh.json';
import new20211129 from './news-list/new_20211129_zh.json';
import new20211129Hidden from './news-list/new_20211129_zh_hidden.json';
import new20220324 from './news-list/new_20220324_zh.json';
import new20220414 from './news-list/new_20220414_zh.json';
import new20220414Hidden from './news-list/new_20220414_zh_hidden.json';
import new20220623 from './news-list/new_20220623_zh.json';
import new20220623Hidden from './news-list/new_20220623_zh_hidden.json';
import news20220823 from './news-list/news_20220823_zh';
import news20220921 from './news-list/news_20220921_zh.json';
import news20220921Hidden from './news-list/news_20220921_zh_hidden.json';
import news20221031 from './news-list/news_20221031_zh.json';
import news20221125 from './news-list/news_20221125_zh.json';
import news20230130 from './news-list/news_20230130_zh.json';
import news20230307 from './news-list/news_20230307_zh.json';
import news20230418 from './news-list/news_20230418_zh.json';
import news20230522 from './news-list/news_20230522_zh.json';
import news20230629 from './news-list/news_20230629_zh.json';
import news20230701 from './news-list/news_20230701_zh';
import news20231108 from './news-list/news_20231108_zh.json';
import news20231205 from './news-list/new_20231205_zh';
import news20231225 from './news-list/news_20231225_zh.json';
import news20240115 from './news-list/news_20240115_zh.json';

const newsZH = {
  title: '企业动态',
  list: [
    {
      id: '20240115',
      date: '2024.01.15',
      type: '活动',
      epitome: '2/22、3/7：上海汉得信息技术股份有限公司 × 飞狼数码（上海）有限公司，共同举办在线研讨会',
      title: '【中国最新信息分享】全面解说中国在生成式AI领域的动向以及中国企业的应用案例',
      mainPicture: '',
      sectionList: news20240115.sectionList,
      done: false,
    },
    {
      id: '20231225',
      date: '2023.12.25',
      type: '活动',
      epitome: '1/30 Pactera Technology International × 飞狼数码（上海）有限公司共同举办在线研讨会',
      title: '【中国最新信息更新】中国碳中和的动态和应对事例、中国生成式AI的动态和应用事例',
      mainPicture: '',
      sectionList: news20231225.sectionList,
      done: false,
    },
    {
      id: '20231205',
      date: '2023.12.05',
      type: '新闻发布',
      epitome: '',
      title: 'Fenrir Inc.作为创始成员加入AI联盟',
      mainPicture: '',
      sectionList: news20231205.sectionList,
      done: false,
    },
    {
      id: '20231108',
      date: '2023.11.08',
      type: '活动',
      epitome: '11月：三周连续举办 飞狼数码（上海）有限公司 主办在线研讨会',
      title: '【发展中国市场的企业必读】2023年中国最新信息分享 有助于发展与壮大中国业务的：生成式AI和UX设计',
      mainPicture: '',
      sectionList: news20231108.sectionList,
      done: true,
    },
    {
      id: '20230701',
      date: '2023.07.01',
      type: '其他',
      epitome: '',
      title: '成都飞狼办公室搬迁的通知',
      mainPicture: '',
      sectionList: news20230701.sectionList,
      done: false,
    },
    {
      id: '20230629',
      date: '2023.06.29',
      type: '活动',
      epitome: '7/19 上海文思海辉信息技术有限公司、飞狼数码（上海）有限公司，2公司共同主办在线研讨会',
      title: '【中国最新信息更新】中国的碳中和相关法律动向及数据跨境传输规定·网络安全法的对策',
      mainPicture: '',
      sectionList: news20230629.sectionList,
      done: true,
    },
    {
      id: '20230522',
      date: '2023.05.22',
      type: '活动',
      epitome: '6月：三周连续举办 飞狼数码（上海）有限公司 主办在线研讨会',
      title: '【发展中国市场的企业必读】2023年中国最新信息分享 有助于发展与壮大中国业务的 应事先落实的三件事',
      mainPicture: '',
      sectionList: news20230522.sectionList,
      done: true,
    },
    {
      id: '20230418',
      date: '2023.04.18',
      type: '活动',
      epitome: '4/25 北京市环球律师事务所・飞狼数码（上海）有限公司　共同举办研讨交流会',
      title: '[发展中国市场的企业必读] 中国的网络安全、数据跨境传输相关的法律、政策趋势及对策',
      mainPicture: '',
      sectionList: news20230418.sectionList,
      done: true,
    },
    {
      id: '20230307',
      date: '2023.03.07',
      type: '活动',
      epitome: '3/23 飞狼数码（上海）有限公司 主办在线研讨会',
      title: '充分利用“企业微信”的讲座～基础功能和强化客户触点 篇～',
      mainPicture: '',
      sectionList: news20230307.sectionList,
      done: true,
    },
    {
      id: '20230130',
      date: '2023.01.30',
      type: '活动',
      epitome: '2/9 飞狼数码（上海）有限公司 主办在线研讨会',
      title: '充分利用“企业微信”的讲座～内部管理和销售支持 篇～',
      mainPicture: '',
      sectionList: news20230130.sectionList,
      done: true,
    },
    {
      id: '20221125',
      date: '2022.11.25',
      type: '活动',
      epitome: '12/22 飞狼数码（上海）有限公司 主办在线研讨会',
      title: '充分利用“企业微信”的讲座～销售支持和客户管理篇～',
      mainPicture: '',
      sectionList: news20221125.sectionList,
      done: true,
    },
    {
      id: '20221031',
      date: '2022.10.31',
      type: '活动',
      epitome: '11/24 飞狼数码（上海）有限公司主办 在线研讨会',
      title: '“企业微信”使用教程完全版～合规对应和安全对策篇～',
      mainPicture: '',
      sectionList: news20221031.sectionList,
      done: true,
    },
    {
      id: '20221012',
      date: '2022.09.21',
      to: null,
      type: '活动',
      epitome: '10/12飞狼数码（上海）有限公司主办 在线研讨会',
      title: '【发展中国市场的企业必读】中国“与数据出境”相关的最新条例、政策转变与实际运用',
      mainPicture: '',
      sectionList: news20220921Hidden.sectionList,
      hidden: true,
      done: true,
    },
    {
      id: '20220921',
      date: '2022.09.21',
      type: '活动',
      epitome: '10/12飞狼数码（上海）有限公司主办 在线研讨会',
      title: '【发展中国市场的企业必读】中国“与数据出境”相关的最新条例、政策转变与实际运用',
      mainPicture: '',
      sectionList: news20220921.sectionList,
      done: true,
    },
    {
      id: '20220909',
      date: '2022.09.09',
      type: '解决方案',
      to: 'https://mp.weixin.qq.com/s/BAewmRAtMDHl44lMwz8iwA',
      epitome: '',
      title: '网络攻击的威胁日益提升，中国网络安全法被广泛要求响应',
      mainPicture: '',
      sectionList: null,
      done: false,
    },
    {
      id: '20220823',
      date: '2022.08.23',
      type: '其他',
      epitome: '',
      title: '飞狼上海办公室搬迁的通知',
      mainPicture: '',
      sectionList: news20220823.sectionList,
      done: false,
    },
    {
      id: '20220623',
      date: '2022.06.23',
      type: '活动',
      epitome: '7/14 TOKAI Communications Corporation・飞狼数码（上海）有限公司・ 缘通株式会社3家公司联合举办在线研讨会',
      title: '关于中国当地法人使用AWS的要点～中国地区和日本地区应该选择哪一个？从实施计划、课题（网络安全法对应等）到解决方案的全面介绍！〜',
      mainPicture: '',
      sectionList: new20220623.sectionList,
      hidden: false,
      done: true,
    },
    {
      id: '20220714',
      date: '2022.06.23',
      type: '活动',
      epitome: '7/14 TOKAI Communications Corporation・飞狼数码（上海）有限公司・ 缘通株式会社3家公司联合举办在线研讨会',
      title: '关于中国当地法人使用AWS的要点～中国地区和日本地区应该选择哪一个？从实施计划、课题（网络安全法对应等）到解决方案的全面介绍！〜',
      mainPicture: '',
      sectionList: new20220623Hidden.sectionList,
      hidden: true,
      done: true,
    },
    {
      id: '20220518',
      date: '2022.04.14',
      type: '活动',
      epitome: '5/18 飞狼数码（上海）有限公司・OKUMURA LTD.,・株式会社缘通3家公司联合举办在线研讨会',
      title: '在中国，新冠疫情下的在家办公和网络安全相关法目前的应对措施',
      mainPicture: '',
      sectionList: new20220414Hidden.sectionList,
      hidden: true,
      done: true,
    },
    {
      id: '20220414',
      date: '2022.04.14',
      type: '活动',
      epitome: '5/18 飞狼数码（上海）有限公司・OKUMURA LTD.,・株式会社缘通3家公司联合举办在线研讨会',
      title: '在中国，新冠疫情下的在家办公和网络安全相关法目前的应对措施',
      mainPicture: '',
      sectionList: new20220414.sectionList,
      done: true,
    },
    {
      id: '20220324',
      date: '2022.03.11',
      type: '活动',
      epitome: '3/24 飞狼数码（上海）有限公司・日铁软件（上海）有限公司联合举办在线研讨会',
      title: '在中国的BtoB市场和安全的电子合同解决方案',
      mainPicture: '',
      sectionList: new20220324.sectionList,
      done: true,
    },
    {
      id: '20211216',
      date: '2021.11.29',
      type: '活动',
      epitome: '12/16 阿里云・飞狼数码（上海）有限公司 联合举办在线研讨会',
      title: '中国的个人信息保护、跨境传输数据和 Salesforce 的有效使用对策',
      mainPicture: '',
      sectionList: new20211129Hidden.sectionList,
      hidden: true,
      done: true,
    },
    {
      id: '20211129',
      date: '2021.11.29',
      type: '活动',
      epitome: '12/16 阿里云・飞狼数码（上海）有限公司 联合举办在线研讨会',
      title: '中国的个人信息保护、跨境传输数据和 Salesforce 的有效使用对策',
      mainPicture: '',
      sectionList: new20211129.sectionList,
      done: true,
    },
    {
      id: '20211029',
      date: '2021.10.29',
      type: '活动',
      epitome: '11/4 飞狼数码（上海）有限公司 在线研讨会',
      title: '在中国最先进的数字解决方案中，什么是真实的用户体验？',
      mainPicture: '',
      sectionList: new20211029.sectionList,
      done: true,
    },
    {
      id: '20210924',
      date: '2021.09.24',
      type: '其他',
      epitome: '',
      title: '成都飞狼办公室搬迁的通知',
      mainPicture: '',
      sectionList: new20210924.sectionList,
      done: false,
    },
    {
      id: '20210827',
      date: '2021.08.27',
      type: '活动',
      epitome: '9/22 面向进入中国市场的企业 在线研讨会',
      title: '中国数据安全法等与合规的应对（更新至2021年最新信息）',
      mainPicture: '',
      sectionList: new20210827.sectionList,
      hidden: true,
      done: true,
    },
    {
      id: '20210825',
      date: '2021.08.25',
      type: '活动',
      epitome: '9/22 面向进入中国市场的企业 在线研讨会',
      title: '中国数据安全法等与合规的应对（更新至2021年最新信息）',
      mainPicture: '',
      sectionList: new20210824.sectionList,
      done: true,
    },
    {
      id: '20210806',
      date: '2021.08.06',
      type: '活动',
      epitome: '8/26 飞狼数码（上海）有限公司 举办在线研讨会',
      title: '利用企业微信和电子发票提升费用结算的办公效率',
      mainPicture: '',
      sectionList: new20210826.sectionList,
      done: true,
    },
    {
      id: '20210628',
      date: '2021.06.28',
      type: '活动',
      epitome: '7/15 飞狼数码（上海）有限公司 举办在线研讨会',
      title: '中国网络安全法和中国个人信息保护法的发展趋势与对策',
      mainPicture: '',
      sectionList: new20210628.sectionList,
      done: true,
    },
    {
      id: '20210527',
      date: '2021.05.27',
      type: '活动',
      epitome: '5/27 飞狼数码（上海）有限公司 举办小型在线网络研讨会',
      title: '利用企业微信进行业务改革',
      mainPicture: '',
      sectionList: new20210527.sectionList,
      done: true,
    },
  ],
  share: '分享',
  doneContent: {
    title: '本次研讨会受理已结束。',
    beforeLink: '如果您想了解今后举行的研讨会信息，请使用',
    link: '此表格',
    afterLink: '进行申请。',
    href: 'contact-us',
  },
  wxScan: '微信扫一扫',
  more: '查看更多',
  back: '企业动态',
  excludeList: ['20210827', '20211216'],
};

export default newsZH;
