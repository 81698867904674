/* eslint-disable max-len */
export default {
  title: '会社情報',
  subtitle1: ['ひとりひとりがデザインと', '技術にこだわり'],
  subtitle2: 'ユーザーのハピネスを実現します',
  description:
    '私たち飛狼（フェイラン）の投資家は、\t日本でデザインと技術にこだわったプロダクトとサービスを制作する Fenrir Inc.です。\n大阪に本社を置き、東京、京都、名古屋、島根に支社があり、\t中国の上海と成都と大連に子会社として私たち飛狼（フェイラン）があります。\n各地の精鋭が切磋琢磨しあうことで、日々成長をつづけています。',
  info: {
    addressTitle: '所在地',
    addressBtnText: '地図を見る',
    postcodeTitle: '',
    phoneNumberTitle: '電話',
    creationDateTitle: '設立日',
  },
  list: [
    {
      id: 'sh',
      title: '上海',
      name: '飛狼数碼（上海）有限公司',
      nameEN: 'Fenrir Shanghai Inc.',
      manager: {
        title: '総経理',
        name: '吉澤 克巳',
      },
      description: [
        'デジタル化が急速に進む中国市場において、それぞれの企業が事業を拡大していくためには、中国国内の新しいサービスと技術を常にフォローし、いち早く活用する運営体制を整えていく必要があります。',
        '中国市場でビジネス展開している企業の課題やニーズにお応えし、お客様の満足を実現させるため、中国最先端のモバイルテクノロジーを駆使することで、クライアント企業様のデジタル化を継続的に支援していきたいと思います。',
      ],
      address: {
        postcode: '200002',
        text: '上海市黄浦区河南南路16号中匯大厦2階',
        poiid: 'B00150C713',
        // location: [121.464391, 31.21858],
      },
      phoneNumber: null,
      creationDate: '2021年4月30日',
    },
    {
      id: 'cd',
      title: '成都',
      name: '成都市飛狼科技有限公司',
      nameEN: 'Fenrir Chengdu Inc.',
      manager: {
        title: '総経理',
        name: '趙 崗',
      },
      description: [
        '徹底したユーザー目線で、お客様にとって最適なソリューションを提供し、お客様と共に新たな価値の創造に取り組み、お客様ひいてはエンドユーザーにハピネスを与えることを目指しております。',
        '飛狼は人材の育成こそが企業発展の礎であることを確信し、一人一人の個性と希望に寄り添ったOJTを実施し、社員のキャリアアップや自己実現ひいてはその家族が幸福な生活を送れるよう全力でサポートしてまいります。',
      ],
      address: {
        postcode: '610000',
        text: '四川省成都市高新区天府軟件園D6棟15階',
        poiid: 'B001C8TY3I',
        // location: [104.067615, 30.551924],
      },
      phoneNumber: '028-85177409',
      creationDate: '2013年10月21日',
    },
    {
      id: 'dl',
      title: '大連',
      name: '大連飛狼科技有限公司',
      nameEN: 'Fenrir Dalian Inc.',
      manager: {
        title: '総経理',
        name: '梁 日棚',
      },
      description: [
        '大連飛狼科技は顧客満足と価値創造の理念に従い、顧客の立場で顧客ニーズを深く理解して高品質のソフトウェア開発サービスとITソリューションを提供しています。',
        '経営管理において、見かけ倒しを極力戒め、個人の創造力と集団優位性を最大限に発揮し、従業員と会社の共同成長を目標としています。取引においては、公正取引を基に、取引先と互恵的なパートナーシップを構築し、社会的に信頼される企業市民になれるよう努めてまいります。',
      ],
      address: {
        postcode: '116085',
        text: '遼寧省大連市高新園区火炬路3号，納米大厦11階',
        poiid: 'B019B0C1WK',
        // location: [121.526098, 38.859573],
      },
      phoneNumber: '0411-83731889',
      creationDate: '2014年12月12日',
    },
  ],
};
